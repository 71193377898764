@import url('https://fonts.googleapis.com/css2?family=Amiri&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/*
  background: -webkit-linear-gradient( #f5c782 20%,#c18d41);
  background-clip: text;
  -webkit-text-fill-color: transparent;

*/
body {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img{
  width: 100%;
  height: auto;
}
h1,
h3,
p,
h5,
a,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

a {
  text-decoration: none !important;
}

.preloaderr {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: white;
}

.navbar {
  width: 100%;
  position: absolute;
  z-index: 88;
  top: 0px;
  text-align: center;
  padding: 12px 160px 30px 130px;
  background-color: #ffc91c !important;

}


.offcanvas {
  background-color: #ffc91c !important;
}

.ImgNav {
  position: relative;
}

.logoImg {
  width: 200px;
  object-fit: contain;

}

/* .logoImg {
  position: absolute;
  left: 20px;
  top: -5px;
  width: 200px;
} */

.offcanlink {
  position: relative;
}

.navgroup a,
.offcanvas-body a {
  color: #000000;
  cursor: pointer;
  height: inherit;
  display: flex;
  align-items: center;
  padding: 5px 13px;
  transition: all .3s ease;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 14px;
  padding-top: 30px;
  line-height: 16px;
  text-transform: none;
  letter-spacing: 0;
  white-space: nowrap;
}


.go4114704207.rsm-date-title,
.go4114704207.rsm-date-title {
  font-size: 16px !important;
}

.go2573613578 {
  margin-top: -30px !important;
}

.offcanlink span {
  color: #097275;
}

.navbar a:hover {
  color: #097275 !important;
}

.offcanvas-body img {
  width: 50px;
}

.offcanvas {
  background-color: #fff;
}

.offcanvas-header .btn-close {
  color: white !important;
  border: 2px solid white !important;
  position: relative;
  background: none !important;
}

.offcanvas-header .btn-close::after {
  content: "X";
  position: absolute;
  color: #000 !important;
  top: 0;
  left: 30%;
  font-size: 20px;
}

.navbar-brand {
  padding: 5px !important;
}

.dropdown-menu.show {
  background-color: #070707 !important;
}

.dropdown-menu.show .dropdown-item {
  color: white !important;
  font-size: 22px !important;
  padding: 0 !important;
}

.textttt {
  margin-left: auto !important;
}


/* 
.fixed .logoImg {
  top: -30px;
} */

.dropdown {
  position: relative;
  display: inline-block;
  padding: 0 !important;
  margin: 0 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #282631;
  min-width: 160px;
  z-index: 1;
  border-radius: 4px !important;

}

.dropdown-content a {
  cursor: pointer;
  height: inherit;
  display: flex;
  align-items: center;
  transition: all .3s ease;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 12px;
  padding-top: 30px;
  line-height: 1em;
  text-transform: none;
  letter-spacing: 0;
  white-space: nowrap;
  color: white;
  padding: 15px;
  text-decoration: none;
  display: block;
  transition: all .3s ease;
  border-bottom: 1px solid white;
}

.dropdown-content a:hover {
  color: #ffc91c !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}



/**************************/
.homeSec1 {
  min-height: 600px;
  font-family: 'Montserrat', sans-serif;
  background-color: #ffc91c;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 140px;
  /* overflow: hidden; */
  position: relative;
}
.homeSec1 .img-banner{
  position: absolute;
  right: 0;
  bottom: -14%;
  left: unset;
  top: unset;
  width: 48%;
}
.homeSec1 h1 {
  color: #097275;
  font-size: 40px;
  margin-top: 35px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-shadow: 0 0 0 transparent;
  line-height: 1em;
  text-transform: none;
  letter-spacing: 0;
  text-align: left;
}

.homeSec1 p {
  color: #282631;
  font-size: 40px;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  line-height: 1em;
  text-transform: none;
  letter-spacing: 0;
  text-align: left;
  margin: 10px 0 !important;
}

/* .homeSec1 .homeImg {
  position: relative;
} */

/* .homeSec1 img {
  position: absolute;
  object-fit: contain;
  position: absolute;
  width: 180%;
  top: -18%;
  left: -80%;
  object-fit: contain;
} */

.button-home {
  font-family: 'Montserrat';
  background-color: #282631;
  color: #ffc91c;
  text-decoration: none;
  font-weight: 700;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  letter-spacing: 0;
  text-transform: none;
  text-shadow: 0 0 0 transparent;
  padding: 12px 22px;
  border-radius: 10px;
  letter-spacing: 0;
  text-shadow: 0 0 0 transparent;
  font-size: 20px;
  transition: 0.2s;
  margin-top: 10px;
  transition: transform 0.7s ease;

}

.button-home:focus {
  text-decoration: none;
}


.button-home:hover {
  transition: transform 0.7s ease;
  box-shadow: 0 8px 5px -5px rgba(0, 0, 0, .25);

  transform: translateY(-10px) !important;
}


/*********************************************/

.homesec2 .row {
  padding: 0 90px;
}

.homesec2 h2 {
  color: #097275;
  font-size: 50px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
  margin-top: 120px;
}



.homesec2 .hoverdetail {
  border-radius: 50px;
  background-color: #e2e2e4;
  width: 100%;
  margin: 0 auto;
  border: none;
  transition: transform 0.7s ease;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 20px;
  min-height: 270px;
  max-width: 350px;
}

.homesec2 .hoverdetail:hover {
  transform: scale(1.1);
}

.homesec2 .hoverdetail h3 {
  line-height: 1.3em;
  letter-spacing: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Montserrat';

}

.homesec2 .hoverdetail p {
  font-family: 'Montserrat';
  color: #000;
  font-size: 18px;
  font-weight: 500;
  padding: 0 10px;
  opacity: 1;
  line-height: 1.3em;
  letter-spacing: 0;
  text-align: center;
}


.homesec2 .hoverdetail img {
  width: 85px;
  height: 85px;
  object-fit: contain;
}
.homesec2 a{
  color: #000;
}
.button-service {
  font-family: 'Montserrat';
  background-color: #282631;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  letter-spacing: 0;
  text-transform: none;
  text-shadow: 0 0 0 transparent;
  border-radius: 10px;
  font-size: 18px;
  transition: 0.2s;
  transition: transform 0.7s ease;
  text-decoration: none;
  padding: 8px 10px;


}

.button-service:focus {
  text-decoration: none;
}


.button-service:hover {
  transition: transform 0.7s ease;
  background-color: #097275;
  transform: translateY(-10px) !important;
}


/******************************/
.homesec3 {
  padding: 60px 0 70px 0;
}

.homesec3 .row {
  padding: 0 70px;
}

.homesec3 h2 {
  color: #097275;
  font-size: 50px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
}



.homesec3 img {
  width: 70%;
  object-fit: contain;
}


.homesec3 .col-md-6 h5 {
  font-family: 'Montserrat';
  color: #000;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 15px;
  opacity: 1;
  line-height: 1.3em;
  letter-spacing: 0;
  width: 94.5%;
  margin-top: 40px;
}

.homesec3 button {
  display: block;
  margin-top: 10px;
  margin: auto;
}

#Strategy.homesec3 .row {
  margin-top: 60px;
}

.homesec3 .col-md-6 h6 {
  margin-bottom: 20px;
}

.homesec3 .col-md-6 h3 {
  font-family: 'Montserrat';
  color: #000;
  font-size: 18px;
  font-weight: 300;
  padding: 0 10px;
  opacity: 1;
  line-height: 1.3em;
  letter-spacing: 0;
  text-align: center;
}

.homesec3 h4 {
  font-family: 'Montserrat';
  color: #000;
  font-size: 18px;
  font-weight: 400;
  padding: 0 10px;
  opacity: 1;
  line-height: 1.3em;
  letter-spacing: 0;
}

.homesec3 h4 span {
  color: #097275;
  font-size: 24px;
  font-weight: bold;
}

.homesec3 h4 span.highlight {
  background-color: #ffc91c;
  margin: 0 5px;
}

.homesec3 li {
  font-family: 'Montserrat';
  color: #000;
  font-size: 19px;
  font-weight: 400;
  padding: 0 10px;
  opacity: 1;
  line-height: 1.3em;
  letter-spacing: 0;
  list-style: none;
}

.homesec3 li svg {
  color: #097275;
  margin-right: 5px;
  display: inline;
  font-size: 20px;
}


.homesec3 .sec3imgdiv .mainimg {
  width: 500px;
  margin-left: 10px;
  object-fit: contain;
}




.homeSecForm {
  align-items: baseline;
  text-align: center;
}

.homeSecForm input {
  background-color: #fff;


  text-align: left;
  width: 80%;
  margin: auto;
  color: #2c3345FF !important;
  border: 1px solid #ACACACFF !important;

  border-radius: 5px !important;
  padding: 8px 15px 8px 15px !important;
  box-shadow: 0px 0px 0px 0px #FFFFFF;
  font-size: 12px;
  font-weight: 300;
  margin-left: 20px;
}

.news .mainimg {
  outline: none !important;
  text-align: center;
}

.news h6 {
  text-align: center;
  font-family: 'Montserrat';
  color: #000;
  font-size: 30px;
  font-weight: 700;
  opacity: 1;
  line-height: 1.3em;
  letter-spacing: 0;
  margin: 20px 0;
}

.colnews {
  margin-top: 80px;
}

.homeSecForm button {
  background-color: #ffc91cFF;
  border: 0px none #FFFFFF;
  border-radius: 5px;
  padding: 9px 50px 9px 50px;
  width: auto;
  white-space: normal;
  box-shadow: 0px 0px 0px 0px #FFFFFF;
  font-weight: 400;
  margin-top: 25px;
  color: #000;
  font-size: 16px;
}

/*****************************************************/
.homesec4 .container {
  padding: 0 70px;
}

.homesec4 h2 {
  color: #097275;
  font-size: 50px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
  margin-top: 120px;
}


.homesec4 h3 {
  text-align: center;
  font-family: 'Montserrat';
  color: #000;
  font-weight: 700;
  opacity: 1;
  line-height: 1.3em;
  letter-spacing: 0;
  margin-top: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid #000;
  padding-bottom: 20px;
  font-size: 23px;
  font-weight: 400;
}

.homesec4 h3 span {
  color: #097275;
  font-size: 26px;
  font-weight: 700;
}


.homesec4 .swiper-slide {
  padding: 0px 0px 30px;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;

}

.homesec4 .swiper-slide img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 20px;
}

.homesec4 .swiper-slide h4 {
  text-align: center;
  font-family: 'Montserrat';
  color: #000;
  font-weight: 400;
  opacity: 1;
  line-height: 1.3em;
  letter-spacing: 0;
  margin: auto;
  margin-top: 10px;
  /* margin-bottom: 30px; */
  padding-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  width: 85%;
}

.homesec4 .iconDiv {
  margin-top: -20px;
}

.homesec4 .iconDiv svg {
  color: #097275;
  margin: 0;
  font-size: 30px;
}

.homesec4 .swiper-slide h4 span {
  color: #ffc91c;
  font-weight: 700;
}

.homesec4 .swiper-slide p {
  font-size: 18px;
  margin-top: 20px;
  font-weight: 500;

  margin-bottom: 0 !important;
}

.homesec4 h6 {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  margin-right: 50px;
  text-align: center;
  border-top: 1px solid #000;
  padding-top: 50px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 23px;
  color: #333;
  font-weight: bold;

}

.homesec4 p.name {
  font-weight: 500;
  font-size: 23px;
  font-family: "Alex Brush";
  color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
}

.homesec4 h6 span {
  color: #097275;
  font-size: 26px;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 4% !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 4% !important;
}

.swiper-button-prev::after,
.swiper-rtl .swiper-button-next::after {
  content: '' !important;
  display: inline-block;
  width: 100px; 
  height: 100px;
  background-image: url(./images/arrow2.png) !important;
  background-size: contain;
  background-repeat: no-repeat;
  color: #097275;

}

.swiper-button-next::after, 
.swiper-rtl .swiper-button-prev::after {
  content: '' !important;
  display: inline-block;
  width: 100px; 
  height: 100px;
  background-image: url(./images/arrow.png) !important;
  background-size: contain;
  background-repeat: no-repeat;
}


/******************************/
/*********scc2***********/
.contactsec2 {
  padding: 30px 200px 100px;
  background-color: #282631;
}

.contactsec2 .row {
  margin-top: 50px;
}

.contactsec2 h2 {
  color: #ffc91c;
  font-size: 50px;
  margin-bottom: 30px;
  position: relative;
  font-weight: 800;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.contactsec2 img {
  width: 100%;
  object-fit: contain;
}


.contactsec2 h5 {
  color: #c3c3c3;
  font-weight: 800;
  font-size: 15px;
  letter-spacing: 1px;
}

.contactsec2 .col-sm-12 p {
  color: #fff;
  font-size: 14px !important;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 17px !important;
  font-family: Inter !important;
}

.contactform h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
  font-family: 'Montserrat', sans-serif;

}

.contactform p {
  color: #fff;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;

}

.contactform input,
.contactform textarea,
.inputPhone {
  width: 100% ;
  padding: 15px 17px;
  border: 2px solid #E4E5E836 !important;
  border-radius: 5px !important;
  padding: 8px 15px 8px 15px !important;
  box-shadow: 0px 0px 0px 0px #FFFFFF;
  outline: none;
  font-size: 14px;
  color: #898989;
  transition: all 0.2s ease-out;
  background-color: #282631 !important;
  border-radius: 2%;
  color: #6e7d84 !important;
}
.inputPhone {
  width: 100% !important;

height: 40px !important;
}
.inputPhone:focus{
  box-shadow: none !important;
}
.react-tel-input .flag-dropdown{
  border: 2px solid #E4E5E836 !important;

  background-color: #282631 !important;

}
.contactform .custom-button {
  width: 100%;
  padding: 15px 17px;
  border: 2px solid #E4E5E836 !important;
  border-radius: 5px !important;
  padding: 8px 15px 8px 15px !important;
  box-shadow: 0px 0px 0px 0px #FFFFFF;
  outline: none;
  font-size: 14px;
  color: #898989;
  transition: all 0.2s ease-out;
  background-color: #282631;
  border-radius: 2%;
  color: #6e7d84 !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactform .d-flex input {
  width: 280px;
}

.contactform input:focus,
.contactform textarea:focus {
  border: 1px solid #097275;
}

.contactform .checkDiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 30px;
}

.contactform .checkDiv span {
  color: #ffc91c;
}

.contactform .checkDiv input {
  width: auto;
  margin-top: 6px;
  margin-right: 10px;
}

.contactform .checkDiv label {
  color: white;
  text-align: left;
}

.contactform button {
  border-radius: 5px !important;
  padding: 8px 15px 8px 15px !important;
  box-shadow: 0px 0px 0px 0px #FFFFFF;
  outline: none;
  border-radius: 2%;
  background: #ffc91c;
  padding: 15px 20px;
  width: 150px;
  margin-top: 50px;
  border: none;
  color: black !important;
  font-size: 18px;
  display: block;
  margin: auto;
  margin-top: 30px;
}

.contactsec2 .paraForm p {
  font-family: 'Montserrat', sans-serif;
  color: white;

  line-height: 1.3em;

  font-weight: 500;
  font-size: 20px;
}

.contactsec2 .paraForm p span {
  color: #ffc91c;
  font-weight: 700;
}
.thanksInquiry p{
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
  font-family: 'Montserrat', sans-serif;
}
.thanksInquiry button{
  border-radius: 5px !important;
  padding: 8px 15px 8px 15px !important;
  box-shadow: 0px 0px 0px 0px #FFFFFF;
  outline: none;
  border-radius: 2%;
  background: #ffc91c;
  padding: 15px 20px;
  width: 250px;
  margin-top: 50px;
  border: none;
  color: black !important;
  font-size: 18px;
  display: block;
  margin: auto;
  margin-top: 30px;
}
.loader {
  width: 30px;
  padding: 5px;
  margin: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #097275;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}
/*************************************/
footer {

  padding: 0px 0px 0;
  min-height: 50vh;
  position: relative;
  background-color: #ffc91c;
}

footer .row {
  padding: 0 150px;
  padding-right: 50px;
}

footer img {
  width: 100px;
  text-align: right;
  object-fit: contain;
  margin-top: 60px;
}

footer .servicesFooter {
  padding-left: 35px;
  padding-top: 55px;

}

footer .emailFooter {
  padding-left: 35px;
  padding-top: 55px;
}

footer .callFooter {
  padding-left: 5px;
  padding-top: 55px;
}

footer h5 {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0px;
  font-weight: 1000;
}

footer p {
  color: #000;
  text-align: justify;
  font-weight: 500;
}

footer a {
  list-style: none;
  color: #000;
  margin-top: 0px;
  display: block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3em;
}

footer a:hover {
  color: #097275;
}

footer .iconsfooter {
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: 50%;
  margin-top: -20px;
}

footer .iconsfooter img {
  width: 45px;
}

footer .copyRight {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: 15px;
  border-top: 1px solid #000;

}

footer .footerBorder {
  padding: 0 70px;
  margin-top: 50px;
}

footer .copyRight a {
  text-decoration: none;
  color: #000;
  margin-right: 10px;
  font-weight: 600;

}

.copyRight div {
  display: flex;
}



.homeremain .hoverdiv {
  width: 95%;
  background-color: #e2e2e4;
  border: none;
  margin-top: 15px;
}

.homeremain .hoverdiv button {
  box-shadow: none;
  border: none;
  padding: 10px;
  background-color: #282631;
  color: white;
}

.homeremain .hoverdiv button:hover {
  box-shadow: none !important;

}

.homeremain .hoverdiv h3 {
  color: #000 !important;
  font-size: 25px;
}

.homeremain .homesec2 .hoverdiv:hover .hoverdetail .button-service {
  box-shadow: none !important;

}





.homeremain .homesec3 .mainimg {
  outline: none;
}


/****************************************/
.aboutt .container {
  padding: 50px 10px;
}

.aboutt .mainimg {
  outline: none;
  width: 100%;
}

.aboutt .homesec3 h5 {
  color: #097275;
  font-size: 40px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
  margin-top: -50px;
}

.aboutt .homesec3 {
  padding: 0 190px;
}

.aboutt .homesec3 img {
  margin-right: 30px;
}

.aboutt .homesec3 .row {
  border-bottom: 1px solid #000;
  padding: 30px 0;
  align-items: center;
}
.differAbout .row {
  border-bottom: 0px !important;

}
/* .homesec3 .borderDiv,
.homesec4 .borderDiv {
  width: 85%;
  background-color: #787878;
  height: 1.5px;
  margin: auto;
} */

.about .homesec3 p {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 500;
  text-align: justify;
  line-height: 1.3em;
}

/* .about .homesec3 {
  padding: 50px ;
} */

.business {
  padding-top: 50px;

}

.business .homesec3 h2 {
  color: #097275;
  font-size: 50px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
  margin-top: 90px;
}

.business .homesec2 h2 {
  color: #097275;
  font-size: 50px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
  margin-top: 90px;
}


.business .homesec3 h3 {

  font-family: 'Montserrat', 'sans-serif';
  color: #000;
  font-size: 17px;
  font-weight: 500;
  padding: 0 10px;
  opacity: 1;
  line-height: 1.5em;
  letter-spacing: 0;
  text-align: left;

}

.business .homesec2 .container p {
  padding: 0 90px;
}

.business .homesec2 .hoverdetail img {
  width: 130px !important;
  height: 130px !important;
  object-fit: contain;
}

.business .homesec2 .hoverdetail img:hover {
  filter: brightness(0.8) saturate(0) hue-rotate(180deg);
}

.business .homesec2 .hoverdetail {
  padding: 10px;
  background-color: #e2e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  text-align: center;
  min-height: 220px !important;
  border-radius: 20px;
  margin: 15px 0;
  max-width: 400px;
}

.business .homesec2 .hoverdetail:hover {
  transform: scale(1);
}

.business .homesec2 .hoverdetail h3 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.business .homesec2 p {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3em;
}

.serviceSec {
  margin-top: 30px;
  padding: 0 190px;
}
.serviceSec a{
  color: #000;
}
.serviceSec .row {
  border-top: 1px solid #000;
  padding: 30px 0;
  align-items: center;
}

.serviceSec .imgDiv {
  border-radius: 30px;
  padding: 20px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2e5e5;
  width: 300px;
  text-align: center;
  margin-left: auto;
}

.serviceSec.serviceb .row {
  border-top: none !important;
}

.serviceSec.serviceb h2 {
  color: #097275;
  font-size: 50px;
  margin-bottom: 9px;
  position: relative;
  font-weight: 900;
  text-align: center;
}

.serviceSec .imgDiv img {
  width: 150px;
}

.serviceSec .imgDiv img:hover {
  filter: brightness(0.8) saturate(0) hue-rotate(180deg);
}

.serviceSec h3 {
  font-size: 23px;
  font-weight: 700;
}

.serviceSec p {
  font-size: 20px;
  font-weight: 300;
  margin-top: 20px;
}

.serviceSec ul {
  list-style: none;
  padding-left: 30px;
  font-size: 20px;
  font-weight: 400;
}

.differ.serviceSec .imgDiv {
  margin-right: auto;
}

/********************/
.calenderr {
  width: 80%;
  min-height: 130vh;
  margin: auto !important;
  border: none !important;
  display: block;
}

.call h2 {
  color: #097275;
  font-size: 35px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
  margin-top: 125px;
}

.call h2 span {
  color: #ffc91c;
  font-weight: 800;
  text-decoration: underline;

}

.call .row {
  padding: 20px 90px;

}

.call p {
  text-align: justify;
  font-family: 'Montserrat';
  color: #000;
  line-height: 1.3em;
  letter-spacing: 0;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 500;
  margin-top: 40px;

}

.call h3 {
  color: #097275;
  font-size: 30px;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  margin-top: 18px;
}

.call ul li {
  list-style: none;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  padding: 0 0 10px 0px;
  opacity: 1;
  line-height: 1.3em;
  letter-spacing: 0;
  list-style: none;
}

.call ul li svg {
  color: #097275;
  font-weight: 1000;
  font-size: 25px;
}

.call h5 {
  text-align: center;
  font-family: 'Montserrat';
  color: #000;
  line-height: 1.3em;
  letter-spacing: 0;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 500;
  margin-top: 40px;

}

.call h5 span {
  color: #097275;
  font-weight: 900;
  text-decoration: underline;
}

.calenderSec {
  display: flex;
  padding: 20px 230px;

}

.backBtn {
  width: 40px;
  height: 40px;
  border: 1px solid rgb(252, 252, 253);
  border-radius: 50%;
  color: #ffc91c;
  background-color: transparent;
  margin-top: 5px;
}

.backBtn svg {
  font-size: 30px;

}

.calenderSec .CallRow {
  background-color: #097275;
  min-height: 700px !important;
  border: 1px solid #fcfcfd33 !important;
  box-shadow: 0 1px 8px #00000014;
  border-radius: 10px;
}

/* .go695645437 {
  box-shadow: none !important;
}

.calenderSec img {
  width: 75px;
  height: 75px;
  object-fit: contain;
}

.go4247048326 .active-day-tile::after {
  background-color: #1a786f !important;
  border-radius: 50%;
  height: 44px;
  width: 44px;

}

/* .go4247048326 .day-tile::after {
  /* left: 20% !important; 
} 

.go4247048326 .react-calendar__tile--active:enabled.day-tile::after,
.go4247048326 .react-calendar__tile--active:enabled:focus.day-tile::after {
  background-color: #ffc91c !important;
}

/* .go4247048326 .day-tile abbr{
  padding-right: 55px !important;
} */
/* .go2080639341 {
  display: none !important;
} 

.go321821519 {
  flex: 0.5 !important;
}

.go4247048326 .active-day-tile abbr {
  color: #ffc91c;

}

/* .go2262683680.top {
  display: none;
} 

.go4247048326 .react-calendar__tile--active:enabled.day-tile abbr,
.go4247048326 .react-calendar__tile--active:enabled:focus.day-tile abbr {
  color: #097275 !important;
}

.go4247048326 .react-calendar__month-view__weekdays__weekday abbr {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #fcfcfd !important;
}

.go4114704207.rsm-date-title {
  font-weight: 400 !important;
  font-size: 18px;
}

.go4247048326 .active-day-tile {
  padding-bottom: 14px !important;
}

.timezones {
  border-radius: 20px !important;
  line-height: 15px;
  padding: 5px 10px;
  color: white;
  background-color: #097275 !important;
  outline: none;
  border: none;
  outline: none !important;
  border: none !important;
  width: 300px !important;
  font-size: 12px !important;
}

.timezoneee {
  margin-top: -80px;
  margin-left: 10px;

}

.go1318713726.rsm-confirm-button {
  border: 1px solid #ffc91c;
  border-radius: 0;
  color: #ffc91c;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 2px !important;
}

.go2556357651.rsm-confirm-button {
  padding: 3px;
  border-radius: 0;
  background: #ffc91c !important;
  height: 50px;
  margin: 0;
  margin-bottom: 10px;
  border-radius: 2px !important;

}

.go3964545171.rsm-cancel-button {
  padding: 3px 5px;
  border: 1px solid rgb(252, 252, 253) !important;
  color: rgb(252, 252, 253) !important;
  border-radius: 0;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 2px !important;

}


.css-1jqq78o-placeholder {
  color: white;
  color: white;
  border-radius: 20px !important;
  line-height: 15px;
  padding: 5px 10px;
  outline: none;
  border: none;
  width: 200px !important;
  outline: none !important;
  border: none !important;
  width: 300px !important;
  font-size: 12px !important
}

.css-t3ipsp-control,
.css-1nmdiq5-menu,
.css-13cymwt-control {
  background-color: #097275 !important;
  color: white;
  border-radius: 20px !important;
  line-height: 15px;
  padding: 5px 10px;
  outline: none !important;
  border: none !important;
  width: 300px !important;
  font-size: 12px !important
}

.css-1jqq78o-placeholder,
.css-1dimb5e-singleValue {
  color: white !important;
}

.selectcall {
  border-left: 1px solid #fcfcfd33;

}

.selectcall p {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  margin-top: 20px;

  color: white;
  word-break: break-word;
}

.calenderSec .callDiv1 h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  margin-top: 10px;

  color: white;
  word-break: break-word;
}

.calenderSec .callDiv1 p {
  color: #fcfcfd99 !important;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  margin-top: 10px;
}

.calenderSec .callDiv1 p svg {
  color: #fcfcfd99 !important;
  font-size: 18px;
}

.schudle2 {
  margin-left: -20px;
}

.formDiv {
  background-color: #282631;
  padding: 30px;
  margin-top: 20px;

}

.schudle2 p {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: 'Montserrat', sans-serif;
  margin-top: 20px;

}

.schudle2 h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
  font-family: 'Montserrat', sans-serif;

}

.schudle2 input,
.schudle2 textarea {
  width: 100%;
  padding: 15px 17px;
  border: 2px solid #E4E5E836 !important;
  border-radius: 5px !important;
  padding: 8px 15px 8px 15px !important;
  box-shadow: 0px 0px 0px 0px #FFFFFF;
  outline: none;
  font-size: 14px;
  color: #898989;
  transition: all 0.2s ease-out;
  background-color: #282631;
  border-radius: 2%;
  color: #6e7d84 !important;
}

.schudle2 input:focus,
.schudle2 textarea:focus {
  background-color: #282631;

  border: 2px solid #E4E5E836 !important;

} */

/* .schudle2 .custom-button {
  width: 100%;
  padding: 15px 17px;
  border: 2px solid #E4E5E836 !important;
  border-radius: 5px !important;
  padding: 8px 15px 8px 15px !important;
  box-shadow: 0px 0px 0px 0px #FFFFFF;
  outline: none;
  font-size: 14px;
  color: #898989;
  transition: all 0.2s ease-out;
  background-color: #282631;
  border-radius: 2%;
  color: #6e7d84 !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} 


.schudle2 input:focus,
.schudle2 textarea:focus {
  border: 1px solid #097275;
}

.schudle2 .checkDiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 30px;
}

.schudle2 .checkDiv span {
  color: #ffc91c;
}

.schudle2 .checkDiv input {
  width: auto;
  margin-top: 6px;
  margin-right: 10px;
}

.schudle2 .checkDiv label {
  color: white;
  text-align: left;
}

.schudle2 button {
  background: #ffc91c;
  padding: 10px;
  width: 180px;
  margin-top: 50px;
  border: none;
  border-radius: 10px;
  color: #097275;
  font-size: 18px;
  display: block;
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.schudle2 .paraForm p {
  font-family: 'Montserrat', sans-serif;

  line-height: 1.3em;

  font-weight: 500;
  font-size: 20px;
}

.contactsec2 .paraForm p span {
  color: #ffc91c;
  font-weight: 700;
}

.form-control:focus {
  box-shadow: none !important;
}

/*************************/
.terms {
  margin-top: 150px;
  padding: 0 100px;
}

.terms h5 {
  display: inline;
  font-size: 16px;
  font-weight: 700;
}

.terms h2 {
  color: #097275;
  font-size: 35px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
}

.terms h3 {
  font-size: 20px;
  margin-bottom: 20px;
  font-family: "Lato";
  font-weight: 700;
  text-align: left;
}

.terms p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Lato";

  line-height: 1.5em;
  letter-spacing: 0;
  text-align: left;
}

.terms div div p {
  text-align: center;
}

.terms div span a {
  color: #000;
  text-decoration: none;
}

.policy {
  margin-top: 150px;
  padding: 0 100px;
}

/* .policy h5 {
  display: inline;
  font-size: 16px;
  font-weight: 700;
} */

.policy h2 {
  color: #097275;
  font-size: 32px;
  margin-top: 20px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
}

.policy h3 {
  font-size: 20px;
  margin-bottom: 20px;
  font-family: "Lato";
  font-weight: 700;
  text-align: left;
}

.policy p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Lato";

  line-height: 1.5em;
  letter-spacing: 0;
  text-align: left;
}

.policy div span a {
  color: #000;
  text-decoration: none;
}

.policy .copyright p {
  text-align: center !important;
  margin: auto;
}

.policy .copyright a {
  color: #000;
  text-decoration: none;
}
/****************************************************************************/
.serviceDetail{

}
 
.serviceDetail {
  padding-top: 50px;

}

.serviceDetail h2 {
  color: #097275;
  font-size: 50px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
  margin-top: 90px;
}

.serviceDetail h3 {
  font-family: 'Montserrat', 'sans-serif';
  color: #000;
  font-size: 17px;
  font-weight: 500;
  padding: 0 10px;
  opacity: 1;
  line-height: 1.5em;
  letter-spacing: 0;
  text-align: left;
}
.serviceDetail p {
  padding: 0 90px;
}

.serviceDetail img {
  width: 100% ;
  height: 60vh;
  object-fit: contain;
  text-align: center;
  margin: auto;
}


.serviceDetail p {
  color: #282631;
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: left;
  margin-top: 30px ;
}
.serviceDetail ul li {
  color: #282631;
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 1.3em;
  text-transform: none;
  list-style: none;
  letter-spacing: 0;
  text-align: left;
  margin-top: 5px ;
}
.serviceDetail ol li {
  color: #282631;
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 1.3em;
  text-transform: none;
  list-style: none;
  letter-spacing: 0;
  text-align: left;
  margin-top: 5px ;
  margin-left: 15px;
}
.serviceDetail h5 {
  display: inline;
  color: #282631;
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: left;
  font-weight: 700;

}
.serviceDetail h3 {
  color: #282631;
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: left;
  margin-top: 30px ;
  padding: 0 90px;

}
.serviceDetail svg {
  color: #097275;
  font-size: 22px;
/* margin-left: 20px; */
}
/* styles.css */
.gmail_signature_prefix {
  color: #888888;
}

.gmail_signature {
  direction: ltr;
}

.signature-table {
  border-spacing: 0px;
  border-collapse: collapse;
  color: #444444;
  font-size: 11pt;
  font-family: Arial, sans-serif;
  background: transparent !important;
}

.signature-cell {
  padding: 0px;
  vertical-align: top;
  line-height: 16px;
}

.inner-table {
  border-spacing: 0px;
  border-collapse: collapse;
  background: transparent !important;
}

.no-padding {
  padding: 0px;
}

.name-title {
  font-weight: 900;
  font-size: 16pt;
  color: #262626;
}

.position-title {
  font-size: 13pt;
  color: #838383;
}

.social-links {
  line-height: 1.38;
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-size: 11pt;
  color: #000000;
  background-color: transparent;
  vertical-align: baseline;
}

.icon {
  background-color: transparent;
  color: #444444;
  font-size: 11pt;
  margin-left: 0px;
  margin-top: 0px;
}

.contact-info {
  color: #262626;
  font-size: 9pt;
}

.icon-cell {
  display: table-cell;
  width: 30px;
}

.contact-icon {
  border: none;
  display: inline-block;
  overflow: hidden;
  width: 19px;
  height: 19px;
  margin-left: 0px;
  margin-top: 0px;
}

.email-link {
  color: #262626;
  font-family: "arial black", sans-serif;
  font-size: 12px;
}

.banner-cell {
  padding: 10px 0px 0px;
}

.banner-image {
  border: 0px;
  vertical-align: middle;
  max-width: 414px;
  height: auto;
}

.disclaimer-cell {
  padding: 10px 0px 0px;
  font-size: 7pt;
  line-height: 10pt;
  color: #959595;
}
.signature-table {
  width: 414px;
  border-spacing: 0;
  border-collapse: collapse;
  color: #444;
  font-size: 11pt;
  font-family: Arial, sans-serif;
  background: transparent!important;
}

.inner-table {
  width: 100%;
}

.signature-cell {
  padding: 0;
  vertical-align: top;
  line-height: 16px;
}

.no-padding {
  padding: 0;
}

.name-title {
  font-weight: 900;
  font-size: 16pt;
  color: #262626;
}

.position-title {
  font-size: 13pt;
  color: #838383;
}

.social-links {
  line-height: 1.38;
  margin-top: 0;
  margin-bottom: 0;
}

.social-links .icon {
  background-color: transparent;
  color: #444;
  font-size: 11pt;
  margin-left: 0;
  margin-top: 0;
}

.contact-info {
  color: #262626;
  font-size: 9pt;
}

.contact-info .contact-icon {
  margin-left: 0;
  margin-top: 0;
}

.email-link {
  font-size: 12px;
}

.banner-cell {
  padding: 10px 0;
}

.banner-image {
  border: 0;
  vertical-align: middle;
  max-width: 414px;
  height: auto;
}

.disclaimer-cell {
  padding: 10px 0;
  font-size: 7pt;
  line-height: 10pt;
  color: #959595;
}


.terms-privacy{
  text-align: center;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 8px;
}
.terms-privacy a{
  color: #ffc91c;
  
}
/*****************************************************************************/

@media only screen and (min-width: 1800px) {
  .homeSec1 .img-banner{
    width: 40%;
    bottom: -16%;
    right: 100px;
  }

}
@media only screen and (min-width: 2200px) {
  .homeSec1 .img-banner{
    width: 35%;
    bottom: -16%;
    right: 100px;
  }

}
@media only screen and (max-height: 372px) {

  .homeSec1 .img-banner{
    width: 42%;
    bottom: -62px;
  }
}

@media only screen and (max-height: 645px) {
  .homeSec1 {
    min-height: 100vh;

  }

  .homeSec1 img {
    position: absolute;
    object-fit: contain;
    position: absolute;
    width: 180%;
    top: 16%;
    left: -60%;
    object-fit: contain;
  }
}

@media only screen and (max-width: 1280px) {
  .homesec8 .swiper-slide {
    padding: 20px;
  }


}
@media only screen and (max-width: 1200px) {

  .homeSec1 .img-banner{
    bottom: -12%;
  
  }
  
  }
@media only screen and (max-width: 1024px) {
  .terms {
    padding: 10px;

  }
  .serviceDetail p {
    padding: 0 10px;
    font-size: 22px;
  }
  .aboutt .homesec3 {
    padding: 10px;
  }

  .navbar {
    padding: 12px 10px 30px 10px;
  }

  .homeSec1 {
    padding-left: 30px;
  }

  .go4247048326 .active-day-tile::after {
    background-color: #1a786f !important;
    border-radius: 50%;
    height: 34px;
    width: 34px;

  }

  .homeSec1 .img-banner{
        right: 0;
    bottom: -75px !important;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .timezones {
    width: 300px;
    margin-bottom: 20px;
  }

  .calenderSec {
    padding: 20px 10px;
  }

  .call .row {
    padding: 20px 10px;
  }

  .sec3imgdiv .mainimg {
    width: 300px;
    height: 300px;
    right: 15%;
  }



  .homesec2 .row {
    padding: 0;
  }

  .serviceSec {
    padding: 0 10px;
  }

  .homesec3 .row {
    padding: 0 !important;
  }

  .homesec3 ul {
    margin-top: 40px;
    padding: 2px !important;
  }

  .homesec3 li {
    padding: 0;
  }

  .homesec4 .container {
    padding: 0 10px;
  }

  .contactsec2 {
    padding: 30px 20px 100px;
    background-color: #282631;
  }

  footer {
    padding: 40px 20px 0px;

  }

  .business .homesec2 .container p {
    padding: 0 10px;
  }

  .homesec8 .swiper-slide {
    padding: 0;
  }

  .homesec8 .swiper-slide .swipertalk {
    padding: 0px 10px 150px;
  }

  .navbar {
    border: 1px solid rgba(255, 255, 255, 0.321);
  }

  .navbar-toggler.collapsed {
    color: #000 !important;
    border: none !important;
  }

  .offcanvas.offcanvas-end.show {
    width: 100%;
  }

  .offcanvas.offcanvas-end.show .textttt.offcanvas-body {
    margin-right: auto;
    margin-left: 0% !important;
  }

  .offcanvas.offcanvas-end.show .textttt.offcanvas-body .navgroup {
    align-items: flex-start !important;
  }

  .navbar-toggler-icon {
    width: 18px !important;
    filter: brightness(0);
  }

  .textttt {
    border: none !important;
  }

  footer .row {
    padding: 0 10px;
    padding-right: 10px;
  }

  footer .servicesFooter {
    padding-left: 5px;
    padding-top: 55px;

  }

  footer .emailFooter {
    padding-left: 5px;
    padding-top: 55px;
  }

  footer .callFooter {
    padding-left: 5px;
    padding-top: 55px;
  }

  .offcanvas-header .btn-close {
    color: black !important;
    opacity: 1 !important;
    border: none !important;
    text-align: left;
    margin: 0 !important;
  }

  .offcanvas-body a:hover {
    color: #097275;
  }

  .navgroup a,
  .offcanvas-body a {

    align-items: center;
    justify-content: center;
    padding: 8px 35px;

    font-weight: 850;
    font-size: 12px;

  }

  .offcanvas-header .btn-close {
    color: black !important;
    opacity: 1 !important;
    border: none !important;
    text-align: left;
    margin: 0 !important;
  }

  .offcanvas-body a:hover {
    color: #097275;
  }

  .navgroup a,
  .offcanvas-body a {

    align-items: center;
    justify-content: center;
    padding: 8px 35px;

    font-weight: 850;
    font-size: 12px;

  }

  .offcanvas.offcanvas-end.show .textttt.offcanvas-body {
    margin-top: -25px;
  }

  .offcanvas-header .btn-close {
    color: black !important;
    opacity: 1 !important;
    border: none !important;
    text-align: left;
    margin: 0 !important;
  }

  .offcanvas-body a:hover {
    color: #097275;
  }

  .navgroup a,
  .offcanvas-body a {

    align-items: center;
    justify-content: center;
    padding: 8px 35px;

    font-weight: 850;
    font-size: 12px;

  }

  .offcanvas.offcanvas-end.show .textttt.offcanvas-body {
    margin-top: -25px;
  }
  .serviceDetail .row {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .calenderr {
    width: 95%;
    min-height: 250vh;
  }
  .aboutt .homesec3 img{
    margin-right: 0;
  }
.aboutt .mainimg{
  margin-bottom: 30px;
}
  .policy {

    padding: 0 10px;
  }

  .homesec4 .swiper-slide h4 {
    width: 100%;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {

    top: 82% !important;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    top: 82% !important;

  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    font-size: 35px !important;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {

    font-size: 35px !important;
  }

  .homesec4 .iconDiv {
    margin-top: 20px;
  }

  footer a {
    font-size: 14px;
  }

  .homeSec1 {
    padding-left: 0;
  }

  .homesec3 .sec3imgdiv .mainimg {
    width: 100%;
  }

  .homesec2 h2,
  .homesec3 h2,
  .homesec4 h2,
  .business h2 ,.serviceDetail h2{
    font-size: 35px;
    margin-top: 40px;
    margin-bottom: 10px;
    font-weight: 700;

  }
  .serviceDetail h2{
    margin-top: 80px;

  }
  .serviceDetail p{
    font-size: 16px;
    padding: 0 5px;
  }
  .serviceDetail h3 {
    font-size: 16px;
    padding: 0 5px;
  }
  .serviceDetail img{
height: 300px;
  }
  .navbar .container-fluid {
    padding: 0 !important;
  }

  .business .homesec3 h2 {
    font-size: 35px;
    font-weight: 700;
  }

  .homeSec1 div h1 {
    font-size: 28px !important;
    margin-top: -160px;
  }

  .homeSec1 div p {
    font-size: 28px !important;
    margin-top: -15px !important;
    padding-left: 2px;

  }

  .homeSec1 div button {
    display: block !important;
    margin: 70px auto 0 !important;

  }

  .homesec4 h2 {
    font-weight: 800;
  }

  .homesec2 .hoverdetail {
    max-width: 100%;
    margin-bottom: 8px;
  }

  .homesec2 {
    padding-bottom: 75px;
  }

  .homesec3 .borderDiv,
  .homesec4 .borderDiv {
    width: 95%;
    background-color: #787878;
    height: 1.5px;
    margin: auto;
  }

  .homesec3 h2 {
    margin-top: 80px;
  }

  .navbar {
    padding: 28px 0px 15px 9px;

    top: 0 !important;
    left: 0 !important;
  }

  .homeSec1 img {

    width: 90% !important;
    left: 5% !important;
    top: 105px;
  }


  .offcanvas.show {
    width: 70%;
  }

  .offcanvas-body a {
    border: none !important;
  }



  .homeSec1 div h1 {
    font-size: 60px;
    padding-left: 2px;
    padding-top: 10px;
  }

  .homeSec1 {
    min-height: 83vh;
  }

  .homesec2 .col-sm-12 {
    margin-top: 10px;
  }

  .homesec3 h4 {
    font-size: 18px;
    padding: 0;
  }

  .homesec3 li {
    font-size: 18px;
  }

  .homesec3 h4 span {
    font-size: 20px;
  }

  #Strategy {
    padding-bottom: 90px;
  }

  .news {
    padding-bottom: 150px;
  }

  .news .row {
    padding: 0 !important;
  }

  .business .homesec2 h2 {
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: 700;
  }

  .business .homesec3 h2 {
    font-size: 28px;
    padding-left: 3px;
    padding-right: 3px;

  }

  .homeSecForm input {
    width: 95%;

  }

  .news .row>* {
    padding: 0 !important;
  }

  .news h4 {
    padding-left: 8px !important;
  }

  .news h6 {
    font-size: 25px;
  }

  .iconDiv {
    margin-top: -20px
  }

  .homesec4 h2 {
    margin-top: 100px;
  }

  .homesec4 .swiper-slide h4 {
    font-size: 18px;
    margin-top: 35px;
    padding-bottom: 0;
  }

  .homesec4 h6 {
    margin-right: 0;
  }

  .contactsec2 h2 {
    font-size: 35px;
    font-weight: 900;
  }

  .contactsec2 .container {
    padding: 0;
  }

  .schudle2 {
    margin-left: 0;
  }

  .contactform {
    padding: 0 38px;
  }

  .contactsec2 .paraForm p {
    font-size: 28px;
  }

  .aboutsec2 img {
    width: 100%;
    object-fit: contain;
  }

  .homesec4 .iconDiv svg {
    font-size: 28px;
  }

  .aboutsec4 img {
    width: 100%;
    object-fit: contain;
  }

  .detailhover {
    top: 0;
  }

  .offcanvas-header .btn-close {
    color: black !important;
    opacity: 1 !important;
    border: none !important;
    text-align: left;
    margin: 0 !important;
  }

  .offcanvas-body a:hover {
    color: #097275;
  }

  .navgroup a,
  .offcanvas-body a {

    align-items: center;
    justify-content: center;
    padding: 8px 35px;

    font-weight: 850;
    font-size: 12px;

  }

  .offcanvas.offcanvas-end.show .textttt.offcanvas-body {
    margin-top: -25px;
  }

  .call p {
    font-size: 18px;
    font-weight: 500;
  }

  .call .row {
    padding: 0 5px !important;
  }

  .call h2,
  .call h3 {
    font-size: 25px;
    font-weight: 600;
  }

  .call h5 {
    font-size: 20px;
  }

  .call ul li svg {
    font-size: 18px;
  }

  .call ul li {
    font-size: 16px;

  }

  .hoverdiv:hover~.detailhover {
    opacity: 1 !important;
    transform: translateY(0);
  }

  .detailhover:hover {
    opacity: 1 !important;
    transform: translateY(0);
  }

  #About .mainimg {
    margin-bottom: 80px;
  }

  #Strategy .mainimg {
    margin-bottom: 30px;
  }

  .clientslider .swiper-slide {
    border-radius: 10px;
  }

  .homesec8 .swiper-slide .swipertalk {
    padding: 0px 10px 150px;
  }

  .homesec8 .swiper-slide {
    padding: 10px;
  }

  .aboutsec5 a {
    margin-top: 40px;
  }

  footer .col-sm-12 {
    margin-top: 30px;
  }



  .projectssec3 .detailimg {
    width: 90%;
    object-fit: cover;
  }

  .groupsec.bg .row {
    flex-direction: column-reverse;
  }

  .contactsec2 .col-sm-12 {
    margin-top: 40px;
  }

  .contactsec3 .info {
    left: 10%;
  }

  .contactsec2 .col-sm-12 {
    padding-right: 0 !important;
  }

  .contactsec2 {
    padding-bottom: 30px;
  }

  .contactsec3 .info {
    width: 256px;
    left: 14.5%;
    padding-bottom: 5px;
  }

  .homesec3 .sec3imgdiv .mainimg {
    margin-left: 0px;
  }

  footer {
    padding-top: 0;
    text-align: center;
    padding: 0 !important;
    padding-bottom: 0 !important;
  }

  footer img {
    margin-top: 30px;
  }

  footer p {
    color: #000;
    font-weight: 300;
    font-size: 14px;
  }

  footer .copyRight,
  .copyRight div {
    flex-direction: column !important;
  }

  footer .footerBorder {
    padding: 0;
  }

  footer .footerBorder p {
    text-align: center;

  }

  .servicesFooter,
  footer .emailFooter,
  footer .callFooter {
    padding-top: 0 !important;
    margin-top: 10px !important;
  }

  footer .iconsfooter {
    justify-content: space-around;
    width: 80%;
    margin-top: 30px;
  }

  footer .emailFooter h5 {
    margin-top: 20px;
  }

  footer .emailFooter p {
    text-align: center;
  }

  footer .emailFooter p:nth-child(3) {
    margin-top: 30px !important;
  }

  .business .homesec2 .hoverdetail {
    margin: auto;
    min-height: 240px !important;
    border-radius: 30px;
  }

  .textttt {
    border: none !important;
  }
}

@media only screen and (max-width: 520px) {
  .homeSec1 {
    min-height: 95vh;
  }

  .homeSec1 div h1 {
    font-size: 28px !important;
    margin-top: -105px;
    font-weight: 1000;
  }

  .homeSec1 div p {
    font-size: 27px !important;
    margin-top: 10px !important;
    font-weight: 1000;
  }
/* 
  .homeSec1 img {
    top: 105px;
    left: 0% !important;
  } */
  .homeSec1 .img-banner {
    bottom: -45px !important;
  }
}

@media screen and (max-width: 290px) {

  .homeSec1 div h1 {
    font-size: 30px !important;
    margin-top: 10px !important;
  }

  .navbar-toggler.collapsed {
    color: white !important;
    margin-left: 90px;
  }



  .navbar.fixed .navbar-toggler.collapsed {
    margin-left: 90px;
  }

  .homeSec1 p {
    line-height: 20px !important;
  }



  .navbar.fixed .navbar-toggler.collapsed {
    margin-left: 80px !important;
  }




}

/* calendar.css */

.CalendarContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
}

.CalendarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CalendarTitle {
  font-weight: 500;
  text-transform: capitalize;
}

.CalendarTitle span {
  color: #777;
}

.CalendarActions {
  display: flex;
  gap: 0.5rem;
  color: #777;
}

.CalendarActions button {
  all: unset;
  cursor: pointer;
  line-height: 0;
  border-radius: 0.25rem;
}

.CalendarActions button svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* Add styles for CalendarBody and CalendarDay as needed */